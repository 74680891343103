/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-fjalla-one';
import 'typeface-lato';
import 'typeface-libre-baskerville';
import 'typeface-lora';
import 'typeface-merriweather';
import 'typeface-montserrat';
import 'typeface-open-sans';
import 'typeface-open-sans-condensed';
import 'typeface-oswald';
import 'typeface-playfair-display';
import 'typeface-prata';
import 'typeface-quattrocento';
import 'typeface-raleway';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import 'typeface-work-sans';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './src/theme/global-style';
import Theme from './src/theme/theme';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
);
