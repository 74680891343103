const theme = {
  fonts: {
    logo: "'Fjalla One', sans-serif",
    serif: "'Merriweather', serif",
    sans: 'Roboto, sans-serif',
    condensed: "'Roboto Condensed', sans-serif",
  },
  colors: {
    primary: '#d2a24f',
    primaryLight: '#d2a24f',
    secondary: '#49b293',
    warning: '#dc3545',
    gray: '#757575',
    grayDark: '#292929',
    grayLight: '#ededed',
    grayBtnLg: '#f3f3f3',
    white: '#ffffff',
    black: '#191919',
    transparent: 'transparent',
    facebook: '#3b5998',
    twitter: '#1da1f2',
    linkedin: '#0077b5',
    whatsapp: '#128c7e',
    email: '#dd4b39',
  },
  gradients: {
    linearOverlay: 'linear-gradient(180deg, rgba(25, 25, 25, 0.3) 0%, rgba(25, 25, 25, 0.4) 75%)',
  },
  spacings: {
    xxSmall: '.25rem',
    xSmall: '.5rem',
    small: '1rem',
    medium: '2rem',
    large: '3rem',
  },
  transforms: {
    button: 'box-shadow .3s ease',
    link: 'color .2s ease',
    transitionShort: 'all 0.15s ease-in-out 0s',
    transitionLong: 'all 0.25s ease-in-out 0s',
    transitionXLong: 'all 0.35s ease-in-out 0s',
    transitionMenu: 'transform .35s ease-in-out',
    transitionCubic: 'all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s',
  },
  shadows: {
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 20px',
    boxShadowTooltip: 'rgba(0, 0, 0, 0.2) 0px 4px 15px',
  },
};
export default theme;
