import { createGlobalStyle } from 'styled-components';
import { mediaQueries } from '../theme/mediaQueries';

export const GlobalStyle = createGlobalStyle`
    html {
            box-sizing:border-box;
            overflow-y:scroll;

            ${'' /* Fluid Typography */}
            font-size: 16px;
            ${mediaQueries('md')`
                font-size: calc(16px + (18 - 16) * ((100vw - 768px) / (1200 - 768)));
            `}
            ${mediaQueries('xl')`
                font-size: 18px;
            `}


    }
    body {
        ${'' /* font:100%/1.6875 ${props => props.theme.fonts.sans}; */}
        font-family: ${(props) => props.theme.fonts.sans};
        font-size: 1rem;
    }
    * {
        box-sizing:inherit;
    }
    *:before {
        box-sizing:inherit;
    }
    *:after {
        box-sizing:inherit;
    }
    *::selection {
        background: ${(props) => props.theme.colors.primary};
    }
    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.black};
        position: relative;
        outline: none;
    }
    a:hover,a:active {
        text-decoration: none;
        color: ${(props) => props.theme.colors.primary};
        outline-width:0;
        outline: none;
    }
    h1,h2,h3,h4,h5,h6 {
        font-family: ${(props) => props.theme.fonts.sans};
    }
    blockquote {
        font-family: ${(props) => props.theme.fonts.serif};
    }
    ${'' /* Text Block Styles */}
    pre {
        font-family: ${(props) => props.theme.fonts.sans};
        font-size: 1rem;
        width: 100%;
        white-space: pre-line;
    }
    .portable-text{
        ${mediaQueries('md')`
            max-width: 35em; 
        `}
        h1,h2,h3,h4,h5,h6 {
            font-family: ${(props) => props.theme.fonts.sans};
        }
        p {
            text-align: justify;
        }
        blockquote {
            font-family: ${(props) => props.theme.fonts.serif};
        }
        a {
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 70%;
                height: 25%;
                width: 100%;
                background-color: ${(props) => props.theme.colors.primary};
                z-index: -1;
            }
        }
        figure {
            margin-left: auto;
            margin-right: auto;
            max-width: 80%;
            display: flex;
            justify-content: center;

            & > img { 
                width: 100%;
            }
        }
        .youtube-in-text {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2rem;
            ${'' /* margin-bottom: 1rem; */}
        }
    }

    /* Masonry */
    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -2rem; /* gutter size offset */
        width: auto;

        ${mediaQueries('lg')`
            margin-left: -2rem;
        `}
        ${mediaQueries('xl')`
            margin-left: -2rem;
        `}
    }
    .my-masonry-grid_column {
        padding-left: 2rem; /* gutter size */
        background-clip: padding-box;

        ${'' /* ${mediaQueries('md')`
            padding-left: 3rem;
        `} */}
        ${mediaQueries('lg')`
            padding-left: 2rem;
        `}
        ${mediaQueries('xl')`
            padding-left: 2rem;
        `}
    }
    
    /* Style your items */
    .my-masonry-grid_column > * { /* change div to reference your elements you put in <Masonry> */
        margin-bottom: 2rem;

        ${mediaQueries('md')`
            margin-bottom: 3rem;
        `}
        ${mediaQueries('lg')`
            margin-bottom: 2rem;
        `}
    }

`;
